import logo from "./logo.svg";
import "./App.css";
import Home from "./Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import TourDetail from "./europe/TourDetail";
import AboutUs from "./about/About";
import Kashmir from "./toursplace/Kashmir";
import Manali from "./toursplace/Manali";
import Contact from "./contact/Contact";
import Tours from "./tours/Tours";
import Dubai from "./International/Dubai";
import BestEurope from "./International/europ/BestEurope";
import Singapur from "./International/Singapur";
import Thailand from "./International/Thailand";
import Glimpses from "./International/europ/Glimpses";
import Wonders from "./International/europ/Wonders";
import Romantic from "./International/europ/Romantic";
import Grandtoure from "./International/europ/Grandtoure";
import London from "./International/europ/London";
import Enquire from "./Enquire";
import WhatsAppButton from "./WhatsAppButton";

function App() {
  return (
    <div className="App">
      <Navbar />
 
      
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/touredetails" element={<TourDetail/>} />
          <Route path="/aboutUs" element={<AboutUs/>} />
          <Route path="/kasmir" element={<Kashmir/>} />
          <Route path="/manali" element={<Manali/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/tours" element={<Tours/>} />
          <Route path="/tours" element={<Tours/>} />
          <Route path="/dubai" element={<Dubai/>} />
          <Route path="/besteurope/:name" element={<BestEurope/>} />
          <Route path="/glimpses" element={<Glimpses/>} />
          <Route path="/wonders" element={<Wonders/>} />
          <Route path="/romantic" element={<Romantic/>} />
          <Route path="/grandtoure" element={<Grandtoure/>} />
          <Route path="/london" element={<London/>} />
          <Route path="/singapur" element={<Singapur/>} />
          <Route path="/thailand" element={<Thailand/>} />
          <Route path="/enq" element={<Enquire/>} />
        </Routes>
      
      <Footer />
      <WhatsAppButton />
    </div>
  );
}

export default App;
