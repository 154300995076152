import React from 'react'

const Kashmir = () => {
  return (
    <div>
      <div class="detail-header">
            <img class="img-fluid" src="./Assets/images/Best-Of-Kashmir-Tour-Detail.jpg" alt=""/>
        </div>
        <div class="row m-0">
            <div class="col-xl-8 col-lg-12">
                <div class="detail-top m-5 d-flex justify-content-between">
                    <div class="tour-detail">
                        <h6 class=" fw-bold text-head">Best of Kashmir</h6>
                        <h6 class="text-content">Departure Date: 16th July, 21st Aug 2024</h6>
                    </div>
                    <div class="days-nights">
                        <div class="days-nights-text text-center">
                            <h6 class="text-duration">5 Nights - 6 Days</h6>
                        </div>
                    </div>
                </div>         
            </div>
            <div class="col-xl-4 col-lg-10 col-md-10 col-sm-12 tour-card">
                <div class="detail-card ">
                    <div class="card rounded-4 ">
                        <div class="card-body d-flex cost">
                            <div class="tour-cost ms-4 ">
                                <h6>From <br/><br/> 17,999 <br/> <span>Per Person Twin Share</span></h6>
                            </div>
                        </div>
                        <div class="container">
                            <div class="d-flex justify-content-evenly trip-facts mt-4 mb-2 py-2">
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="plane" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path d="M482.3 192C516.5 192 576 221 576 256C576 292 516.5 320 482.3 320H365.7L265.2 495.9C259.5 505.8 248.9 512 237.4 512H181.2C170.6 512 162.9 501.8 165.8 491.6L214.9 320H112L68.8 377.6C65.78 381.6 61.04 384 56 384H14.03C6.284 384 0 377.7 0 369.1C0 368.7 .1818 367.4 .5398 366.1L32 256L.5398 145.9C.1818 144.6 0 143.3 0 142C0 134.3 6.284 128 14.03 128H56C61.04 128 65.78 130.4 68.8 134.4L112 192H214.9L165.8 20.4C162.9 10.17 170.6 0 181.2 0H237.4C248.9 0 259.5 6.153 265.2 16.12L365.7 192H482.3z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Flight</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="hotel" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M480 0C497.7 0 512 14.33 512 32C512 49.67 497.7 64 480 64V448C497.7 448 512 462.3 512 480C512 497.7 497.7 512 480 512H304V448H208V512H32C14.33 512 0 497.7 0 480C0 462.3 14.33 448 32 448V64C14.33 64 0 49.67 0 32C0 14.33 14.33 0 32 0H480zM112 96C103.2 96 96 103.2 96 112V144C96 152.8 103.2 160 112 160H144C152.8 160 160 152.8 160 144V112C160 103.2 152.8 96 144 96H112zM224 144C224 152.8 231.2 160 240 160H272C280.8 160 288 152.8 288 144V112C288 103.2 280.8 96 272 96H240C231.2 96 224 103.2 224 112V144zM368 96C359.2 96 352 103.2 352 112V144C352 152.8 359.2 160 368 160H400C408.8 160 416 152.8 416 144V112C416 103.2 408.8 96 400 96H368zM96 240C96 248.8 103.2 256 112 256H144C152.8 256 160 248.8 160 240V208C160 199.2 152.8 192 144 192H112C103.2 192 96 199.2 96 208V240zM240 192C231.2 192 224 199.2 224 208V240C224 248.8 231.2 256 240 256H272C280.8 256 288 248.8 288 240V208C288 199.2 280.8 192 272 192H240zM352 240C352 248.8 359.2 256 368 256H400C408.8 256 416 248.8 416 240V208C416 199.2 408.8 192 400 192H368C359.2 192 352 199.2 352 208V240zM256 288C211.2 288 173.5 318.7 162.1 360.2C159.7 373.1 170.7 384 184 384H328C341.3 384 352.3 373.1 349 360.2C338.5 318.7 300.8 288 256 288z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Hotel</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="camera" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M194.6 32H317.4C338.1 32 356.4 45.22 362.9 64.82L373.3 96H448C483.3 96 512 124.7 512 160V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V160C0 124.7 28.65 96 64 96H138.7L149.1 64.82C155.6 45.22 173.9 32 194.6 32H194.6zM256 384C309 384 352 341 352 288C352 234.1 309 192 256 192C202.1 192 160 234.1 160 288C160 341 202.1 384 256 384z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Exploring</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="bowl-food" xmlns="http://www.w3.org/2000/svg" viewBox="35 10 255 255">
                                            <path d="M163.816,222.91c38.745,0,70.269-31.514,70.269-70.259c0-38.745-31.523-70.274-70.269-70.274    c-38.743,0-70.264,31.524-70.264,70.274C93.552,191.396,125.068,222.91,163.816,222.91z"></path> <path d="M163.816,241.771c49.226,0,89.118-39.903,89.118-89.119c0-49.228-39.893-89.123-89.118-89.123    c-49.221,0-89.119,39.895-89.119,89.123C74.697,201.867,114.595,241.771,163.816,241.771z M163.816,77.183    c41.608,0,75.469,33.862,75.469,75.469c0,41.609-33.86,75.464-75.469,75.464c-41.609,0-75.464-33.854-75.464-75.464    C88.352,111.045,122.207,77.183,163.816,77.183z"></path><path d="M302.459,250.062l-5.2-200.026c0-4.307-3.493-7.8-7.8-7.8c-1.152,0-2.234,0.264-3.214,0.718v-0.074    c0,0-0.203,0.13-0.493,0.328c-0.746,0.412-1.416,0.919-1.995,1.539c-5.708,4.685-23.907,23.78-25.431,49.592    c-2.311,39.372,16.813,55.317,23.328,69.921l-5.199,85.798c0,4.306,8.693,7.8,12.999,7.8    C293.766,257.862,302.459,254.369,302.459,250.062z"></path><path d="M13.614,128.206l13.461,6.724c1.534,0.769,3.225,1.33,4.992,1.747l-5.2,117.007c0,3.615,8.696,6.54,13,6.54    c4.306,0,13-2.925,13-6.54l-5.2-117.606c1.831-0.576,3.595-1.279,5.139-2.224l9.973-6.073c7.196-4.39,12.619-14.045,12.619-22.475    V52.88c0-4.307-3.494-7.8-7.8-7.8c-4.304,0-7.8,3.493-7.8,7.8v47.225h-4.332V52.88c0-4.307-3.494-7.8-7.8-7.8    c-4.304,0-7.8,3.493-7.8,7.8v47.225h-4.347V52.88c0-4.307-3.494-7.8-7.8-7.8c-4.304,0-7.8,3.493-7.8,7.8v47.225H15.6V54.565    c0-4.306-3.494-7.8-7.8-7.8c-4.304,0-7.8,3.494-7.8,7.8v51.612C0,114.852,5.855,124.327,13.614,128.206z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Meals</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="passport" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M129.6 208c5.25 31.25 25.62 57.13 53.25 70.38C175.3 259.4 170.3 235 168.8 208H129.6zM129.6 176h39.13c1.5-27 6.5-51.38 14.12-70.38C155.3 118.9 134.9 144.8 129.6 176zM224 286.8C231.8 279.3 244.8 252.3 247.4 208H200.5C203.3 252.3 216.3 279.3 224 286.8zM265.1 105.6C272.8 124.6 277.8 149 279.3 176h39.13C313.1 144.8 292.8 118.9 265.1 105.6zM384 0H64C28.65 0 0 28.65 0 64v384c0 35.35 28.65 64 64 64h320c35.2 0 64-28.8 64-64V64C448 28.8 419.2 0 384 0zM336 416h-224C103.3 416 96 408.8 96 400S103.3 384 112 384h224c8.75 0 16 7.25 16 16S344.8 416 336 416zM224 320c-70.75 0-128-57.25-128-128s57.25-128 128-128s128 57.25 128 128S294.8 320 224 320zM265.1 278.4c27.62-13.25 48-39.13 53.25-70.38h-39.13C277.8 235 272.8 259.4 265.1 278.4zM200.6 176h46.88C244.7 131.8 231.8 104.8 224 97.25C216.3 104.8 203.2 131.8 200.6 176z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Visa</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="clipboard-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32s-14.33 32-32 32S160 113.7 160 96S174.3 64 192 64zM282.9 262.8l-88 112c-4.047 5.156-10.02 8.438-16.53 9.062C177.6 383.1 176.8 384 176 384c-5.703 0-11.25-2.031-15.62-5.781l-56-48c-10.06-8.625-11.22-23.78-2.594-33.84c8.609-10.06 23.77-11.22 33.84-2.594l36.98 31.69l72.52-92.28c8.188-10.44 23.3-12.22 33.7-4.062C289.3 237.3 291.1 252.4 282.9 262.8z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Insurance</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="tour-card-detail">
                                <span class="d-flex my-3"><img src="./Assets/images/tour-card-icon1.png" alt="" /><h6 class="m-2" >SA surprises</h6></span>
                                <span class="d-flex my-3"><img src="./Assets/images/tour-card-icon2.png" alt="" /><h6 class="m-2" >SA Price Guarantee</h6></span>
                                <span class="d-flex mt-3"><img src="./Assets/images/tour-card-icon3.png" alt="" /><h6 class="m-2" >SA Customers Unedited Review</h6></span>
                            </div>
                        </div>
                        <div class="card-body p-4 w-100">
                            <button class=" px-4 py-3 tour-card-button"><a href="">Enquire Now</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-xl-8 itenary">
                <div class="mx-5 accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header m-4 head">Itineray </h2>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Day 1: Arrival and Sightseeing in Srinagar
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul>
                                    <li>
                                        Upon arrival at Srinagar, our executive will receive you with a warm welcome in the lake city. You will be taken to the Houseboat, and after checking in, you will proceed for sightseeing. Visit the world-famous gardens: Nishat Bagh (The Garden of Pleasure), Shalimar Bagh (Abode of Love), and Chashme Shahi Garden, and also visit the Shankaracharya Temple. Later, enjoy a Shikara ride on Dal Lake. In the evening, head back to your houseboat for an overnight stay.
                                    </li>
                                    <li>
                                        Nishat Garden: The second-largest Mughal Garden in Kashmir, built in 1633 AD by Asaf Khan. A terraced garden on the eastern side of Dal Lake.
                                    </li>
                                    <li>
                                        Shalimar Garden: The largest of the three Mughal Gardens in Srinagar, commissioned by Mughal Emperor Jahangir in 1619 for his wife Noor Jahan.
                                    </li>
                                    <li>
                                        Chashme Shahi: The smallest Mughal Garden, famous for its cold water spring with believed medicinal properties.
                                    </li>
                                    <li>
                                        Srinagar: Known for its natural beauty, rich history, and unique houseboat stays. Home to the Hazratbal shrine and famous for Shikara rides, Mughal Gardens, Pari Mahal, and Hari Parbat Fort.
                                    </li>
                                    <li>
                                        Accommodation & Dinner Only
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Day 2: Srinagar to Sonmarg
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul>
                                    <li>
                                        Begin your day with breakfast, then check out from the houseboat and check into the Srinagar hotel. Enjoy a day tour of Sonmarg, known as the "Meadow of Gold". Experience a road trip with stunning hill views and enjoy a horse ride to Thajiwas Glacier, covered with snow year-round. Sonmarg is also known as the Gateway to Ladakh. Capture these beautiful moments and return to Srinagar for an overnight stay at your hotel.
                                    </li>
                                    <li>
                                        Sonmarg: Known for its scenic beauty and Thajiwas Glacier. Ideal for treks, trout fishing, and river rafting.
                                    </li>
                                    <li>
                                        Accommodation, Breakfast & Lunch or Dinner
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Day 3: Srinagar to Gulmarg
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul>
                                    <li>
                                        After breakfast, check out from the hotel for a day tour of Gulmarg. Enjoy a road trip to Gulmarg, known for its Gondola ride – Asia's largest cable car project. The 8-minute ride takes you to the lower ridge of Apharwat Peak. Return to Srinagar for an overnight stay at your hotel.
                                    </li>
                                    <li>
                                        Gulmarg: Famous for its Gondola ride, world’s highest golf course, skiing, and snowboarding. 
                                    </li>
                                    <li>
                                        Accommodation, Breakfast & Lunch or Dinner
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>                   
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Day 4: Srinagar to Pahalgam
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul>
                                    <li>
                                        After breakfast, check out from the Srinagar hotel and proceed to Pahalgam, known as “The Valley of Shepherds”. Transfer to the Pahalgam hotel, and after lunch, explore the area and enjoy the magnificent views of the mountains. Overnight stay in Pahalgam Hotel.
                                    </li>
                                    <li>
                                        Pahalgam: A popular tourist destination and film industry preference, known for its saffron fields, Awantipur ruins, and picturesque countryside.
                                    </li>
                                    <li>
                                        Accommodation, Breakfast & Lunch or Dinner
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>    
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Day 5: Sightseeing in Pahalgam
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul>
                                    <li>
                                        After breakfast, enjoy sightseeing in Pahalgam. Visit Chandanwadi and Snow Point (approx. 14km from Pahalgam), and enjoy a pony ride (own expense). Return to the hotel for an overnight stay.
                                    </li>
                                    <li>
                                        Note: Outside vehicles are not allowed for sightseeing in Pahalgam; only local taxis can operate within the area.
                                    </li>
                                    <li>
                                        Accommodation, Breakfast & Lunch or Dinner
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> 
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSix">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Day 6: Departure from Pahalgam
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <ul>
                                    <li>
                                        After breakfast, check out from the hotel. Our representative will take you to Srinagar Airport for your onward journey. Due to strict security, leave from the hotel at least 4 hours before departure. Expect to pass through 4 security barriers, which may lead to long traffic jams.
                                    </li>
                                    <li>
                                        Breakfast Only
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>                                                                                 
                </div>
                {/* <!-- Inclusions Section --> */}
                <div class="inclusions m-5 p-4">
                    <h5 class="inclusion-heading">Inclusions</h5>
                    <div class="inclusion-lists">
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Pick up and drop from Srinagar Airport</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Sightseeing as per the itinerary</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Accommodation in hotel & houseboat</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>All applicable taxes except GST</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>One hour Shikara ride on Dal Lake once during the stay</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Accommodation on Double Sharing Basis</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Welcome Non-alcoholic drink on arrival</h6>
                        </div>
                    </div>
                </div>

                {/* <!-- Exclusions Section --> */}
                <div class="exclusions m-5 p-4">
                    <h5 class="inclusion-heading">Exclusions</h5>
                    <div class="inclusion-lists">
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Guide fees & Monument Entrance Fees & Camera Fees</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Airfare/Train fare</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Room Heater charges as per the hotel</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>5% GST Extra</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Any Adventure activities</h6>
                        </div>
                    </div>
                </div>

                {/* <!-- Payment Policy Section --> */}
                <div class="payment-policy p-4">
                    <h5 class="policy-head">Payment Policy</h5>
                    <div class="policy-content">
                        <h6>
                            <ul>
                                <li>Booking amount is minimum Rs.30,000 per person if booking is done 45 days before travel.</li>
                                <li>Within 21-45 days of travel, a minimum 25% booking deposit is required to confirm the booking.</li>
                                <li>Within 21 days of travel, 100% of the amount is required to confirm the booking.</li>
                                <li>You can pay using credit cards, debit cards, net banking, NEFT, IMPS, RTGS, SWIFT in adherence to Income Tax ACT.</li>
                                <li>We charge non-refundable financing fees for overdue amounts:
                                    <ul>
                                        <li>Financing charges: 1% per day of pending amount, compounding per day</li>
                                        <li>Processing charges for financing: 5% per week of pending amount or INR 10,000 per week, whichever is lower; from 2nd week</li>
                                    </ul>
                                </li>
                                <li>We reserve the right to cancel your trip without notice after the due date. Any cancellation costs will be borne by you.</li>
                                <li>If the above-mentioned payment terms are not followed, we will not be held responsible for any change made in the package itinerary due to non-availability of flights, hotels, ferry, etc.</li>
                                <li>There might be an increase in total package cost offered at the time of bookings in case the payments are not received by us as per the terms mentioned, and the extra cost needs to be borne by the guest.</li>
                                <li>We will never ask you to pay into a personal account. Please always pay using our website or in our company bank account.</li>
                            </ul>
                        </h6>
                    </div>
                </div>

                {/* <!-- Cancellation Policy Section --> */}
                <div class="cancellation-policy p-4">
                    <h5 class="policy-head">Cancellation Policy</h5>
                    <div class="policy-content">
                        <h6>
                            <ul>
                                <li>The Tour can be cancelled 30 days before travel by paying 10% of the tour value as cancellation charges.</li>
                                <li>Within 15-30 days of travel, the tour can be cancelled by paying 50% of the cancellation charge.</li>
                                <li>Within 15 days, the tour is completely non-refundable.</li>
                            </ul>
                        </h6>
                    </div>
                </div>              
            </div>
        </div>
        
    </div>
  )
}

export default Kashmir
