import React from 'react'

const Grandtoure = () => {
  return (
    <div>
      <div class="detail-header">
            <img class="img-fluid" src="./Assets/images/Grand-Tour-of-Europe-Tour Detail.jpg" alt=""/>
        </div>        
        <div class="row m-0">
            <div class="col-xl-8 col-lg-12">
                <div class="detail-top m-5 d-flex justify-content-between">
                    <div class="tour-detail">
                        <h6 class=" fw-bold text-head">Grand Tour of Europe</h6>
                        <h6 class="text-content">Departure Date: 16th July, 21st Aug 2024</h6>
                    </div>
                    <div class="days-nights">
                        <div class="days-nights-text text-center">
                            <h6 class="text-duration">15 Nights - 16 Days</h6>
                        </div>
                    </div>
                </div>         
            </div>
            <div class="col-xl-4 col-lg-10 col-md-10 col-sm-12 tour-card">
                <div class="detail-card ">
                    <div class="card rounded-4 ">
                        <div class="card-body d-flex cost">
                            <div class="tour-cost ms-4 ">
                                <h6>From <br/><br/> 351,009 <br/> <span>Per Person Twin Share</span></h6>
                            </div>
                        </div>
                        <div class="container">
                            <div class="d-flex justify-content-evenly trip-facts mt-4 mb-2 py-2">
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="plane" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path d="M482.3 192C516.5 192 576 221 576 256C576 292 516.5 320 482.3 320H365.7L265.2 495.9C259.5 505.8 248.9 512 237.4 512H181.2C170.6 512 162.9 501.8 165.8 491.6L214.9 320H112L68.8 377.6C65.78 381.6 61.04 384 56 384H14.03C6.284 384 0 377.7 0 369.1C0 368.7 .1818 367.4 .5398 366.1L32 256L.5398 145.9C.1818 144.6 0 143.3 0 142C0 134.3 6.284 128 14.03 128H56C61.04 128 65.78 130.4 68.8 134.4L112 192H214.9L165.8 20.4C162.9 10.17 170.6 0 181.2 0H237.4C248.9 0 259.5 6.153 265.2 16.12L365.7 192H482.3z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Flight</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="hotel" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M480 0C497.7 0 512 14.33 512 32C512 49.67 497.7 64 480 64V448C497.7 448 512 462.3 512 480C512 497.7 497.7 512 480 512H304V448H208V512H32C14.33 512 0 497.7 0 480C0 462.3 14.33 448 32 448V64C14.33 64 0 49.67 0 32C0 14.33 14.33 0 32 0H480zM112 96C103.2 96 96 103.2 96 112V144C96 152.8 103.2 160 112 160H144C152.8 160 160 152.8 160 144V112C160 103.2 152.8 96 144 96H112zM224 144C224 152.8 231.2 160 240 160H272C280.8 160 288 152.8 288 144V112C288 103.2 280.8 96 272 96H240C231.2 96 224 103.2 224 112V144zM368 96C359.2 96 352 103.2 352 112V144C352 152.8 359.2 160 368 160H400C408.8 160 416 152.8 416 144V112C416 103.2 408.8 96 400 96H368zM96 240C96 248.8 103.2 256 112 256H144C152.8 256 160 248.8 160 240V208C160 199.2 152.8 192 144 192H112C103.2 192 96 199.2 96 208V240zM240 192C231.2 192 224 199.2 224 208V240C224 248.8 231.2 256 240 256H272C280.8 256 288 248.8 288 240V208C288 199.2 280.8 192 272 192H240zM352 240C352 248.8 359.2 256 368 256H400C408.8 256 416 248.8 416 240V208C416 199.2 408.8 192 400 192H368C359.2 192 352 199.2 352 208V240zM256 288C211.2 288 173.5 318.7 162.1 360.2C159.7 373.1 170.7 384 184 384H328C341.3 384 352.3 373.1 349 360.2C338.5 318.7 300.8 288 256 288z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Hotel</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="camera" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M194.6 32H317.4C338.1 32 356.4 45.22 362.9 64.82L373.3 96H448C483.3 96 512 124.7 512 160V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V160C0 124.7 28.65 96 64 96H138.7L149.1 64.82C155.6 45.22 173.9 32 194.6 32H194.6zM256 384C309 384 352 341 352 288C352 234.1 309 192 256 192C202.1 192 160 234.1 160 288C160 341 202.1 384 256 384z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Exploring</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="bowl-food" xmlns="http://www.w3.org/2000/svg" viewBox="35 10 255 255">
                                            <path d="M163.816,222.91c38.745,0,70.269-31.514,70.269-70.259c0-38.745-31.523-70.274-70.269-70.274    c-38.743,0-70.264,31.524-70.264,70.274C93.552,191.396,125.068,222.91,163.816,222.91z"></path> <path d="M163.816,241.771c49.226,0,89.118-39.903,89.118-89.119c0-49.228-39.893-89.123-89.118-89.123    c-49.221,0-89.119,39.895-89.119,89.123C74.697,201.867,114.595,241.771,163.816,241.771z M163.816,77.183    c41.608,0,75.469,33.862,75.469,75.469c0,41.609-33.86,75.464-75.469,75.464c-41.609,0-75.464-33.854-75.464-75.464    C88.352,111.045,122.207,77.183,163.816,77.183z"></path><path d="M302.459,250.062l-5.2-200.026c0-4.307-3.493-7.8-7.8-7.8c-1.152,0-2.234,0.264-3.214,0.718v-0.074    c0,0-0.203,0.13-0.493,0.328c-0.746,0.412-1.416,0.919-1.995,1.539c-5.708,4.685-23.907,23.78-25.431,49.592    c-2.311,39.372,16.813,55.317,23.328,69.921l-5.199,85.798c0,4.306,8.693,7.8,12.999,7.8    C293.766,257.862,302.459,254.369,302.459,250.062z"></path><path d="M13.614,128.206l13.461,6.724c1.534,0.769,3.225,1.33,4.992,1.747l-5.2,117.007c0,3.615,8.696,6.54,13,6.54    c4.306,0,13-2.925,13-6.54l-5.2-117.606c1.831-0.576,3.595-1.279,5.139-2.224l9.973-6.073c7.196-4.39,12.619-14.045,12.619-22.475    V52.88c0-4.307-3.494-7.8-7.8-7.8c-4.304,0-7.8,3.493-7.8,7.8v47.225h-4.332V52.88c0-4.307-3.494-7.8-7.8-7.8    c-4.304,0-7.8,3.493-7.8,7.8v47.225h-4.347V52.88c0-4.307-3.494-7.8-7.8-7.8c-4.304,0-7.8,3.493-7.8,7.8v47.225H15.6V54.565    c0-4.306-3.494-7.8-7.8-7.8c-4.304,0-7.8,3.494-7.8,7.8v51.612C0,114.852,5.855,124.327,13.614,128.206z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Meals</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="passport" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M129.6 208c5.25 31.25 25.62 57.13 53.25 70.38C175.3 259.4 170.3 235 168.8 208H129.6zM129.6 176h39.13c1.5-27 6.5-51.38 14.12-70.38C155.3 118.9 134.9 144.8 129.6 176zM224 286.8C231.8 279.3 244.8 252.3 247.4 208H200.5C203.3 252.3 216.3 279.3 224 286.8zM265.1 105.6C272.8 124.6 277.8 149 279.3 176h39.13C313.1 144.8 292.8 118.9 265.1 105.6zM384 0H64C28.65 0 0 28.65 0 64v384c0 35.35 28.65 64 64 64h320c35.2 0 64-28.8 64-64V64C448 28.8 419.2 0 384 0zM336 416h-224C103.3 416 96 408.8 96 400S103.3 384 112 384h224c8.75 0 16 7.25 16 16S344.8 416 336 416zM224 320c-70.75 0-128-57.25-128-128s57.25-128 128-128s128 57.25 128 128S294.8 320 224 320zM265.1 278.4c27.62-13.25 48-39.13 53.25-70.38h-39.13C277.8 235 272.8 259.4 265.1 278.4zM200.6 176h46.88C244.7 131.8 231.8 104.8 224 97.25C216.3 104.8 203.2 131.8 200.6 176z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Visa</h6>
                                    </div>
                                </div>
                                <div class="facilities">
                                    <span class="facilities-icon">
                                        <svg fill="currentColor" class="svg-inline--fa p-1 rounded-circle" data-prefix="fas" data-icon="clipboard-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32s-14.33 32-32 32S160 113.7 160 96S174.3 64 192 64zM282.9 262.8l-88 112c-4.047 5.156-10.02 8.438-16.53 9.062C177.6 383.1 176.8 384 176 384c-5.703 0-11.25-2.031-15.62-5.781l-56-48c-10.06-8.625-11.22-23.78-2.594-33.84c8.609-10.06 23.77-11.22 33.84-2.594l36.98 31.69l72.52-92.28c8.188-10.44 23.3-12.22 33.7-4.062C289.3 237.3 291.1 252.4 282.9 262.8z"></path>
                                        </svg>
                                    </span>
                                    <div>
                                        <h6 class="facilities-text">Insurance</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="tour-card-detail">
                                <span class="d-flex my-3"><img src="./Assets/images/tour-card-icon1.png" alt=""/><h6 class="m-2">SA surprises</h6></span>
                                <span class="d-flex my-3"><img src="./Assets/images/tour-card-icon2.png" alt=""/><h6 class="m-2">SA Price Guarantee</h6></span>
                                <span class="d-flex mt-3"><img src="./Assets/images/tour-card-icon3.png" alt=""/><h6 class="m-2">SA Customers Unedited Review</h6></span>
                            </div>
                        </div>
                        <div class="card-body p-4 w-100">
                            <button class=" px-4 py-3 tour-card-button"><a href="">Enquire Now</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-xl-8 itenary">
                <div class="mx-5 accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header m-4 head">Itinerary</h2>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Day 1: Arrival In Paris – The City Of Romance, Lights And Glamour
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                We can’t wait to welcome your arrival in Paris, the city of romance and glamour. After going through the immigration process, your guide will be waiting to escort you to the hotel and help with check-in. After check-in, spend your day at your own pace.
                                <br/><br/>
                                <strong>Overnight in Paris. (Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Day 2: Paris City Tour – Eiffel Tower 2nd Level And Romantic River Seine Cruise
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Today we proceed for a guided city tour of Paris. Marvel at the finest Parisian tourist attractions, Place Vendôme, Place de l’Opéra Garnier, Musée d’Orsay, Place de la Concorde, Champs Elysées, Arc de Triomphe, Alexander Bridge, Les Invalides, and several others.
                                <br/><br/>
                                Next, we visit the iconic Eiffel Tower – 2nd Level, and get a stunning view of the city from the top. We then take a romantic cruise down the river Seine, flanked by exquisite monuments like Notre Dame, Eiffel Tower, Louvre, Orsay Museum, and many more.
                                <br/><br/>
                                <strong>Overnight in Paris. (Breakfast, Lunch and Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Day 3: Enjoy Your Fun Filled Day At Disneyland Paris
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Experience a day full of excitement at this popular amusement park. You may choose between Disney Parks or Walt Disney Studios Park.
                                <br/><br/>
                                <strong>Overnight in Paris. (Breakfast, Packed Lunch, Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Day 4: Transfer To Brussels And Visit Grand Place, Manneken Pis Statue And Atomium Photostop
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Today we proceed to Brussels, Headquarters of the European Union and capital of Belgium. On arrival in Brussels, we visit Grand Place, home to the medieval Town Hall and the fabled Manneken Pis statue. Drive past St. Michael’s Church and stop for a photograph at the Atomium. Later we move to Amsterdam for check-in.
                                <br/><br/>
                                <strong>Overnight in Amsterdam. (Breakfast, Lunch and Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Day 5: Explore Amsterdam With Canal Cruise – Visit Keukenhof Gardens (Until 12th May) Or The Zaanse Schans (From 13th May) – Amsterdam Orientation Tour With Dam Square
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Enjoy your breakfast before checking out to explore the Netherlands. Before 13th May, visit Keukenhof Gardens. Afterward, or from 13th May onwards, visit Zaanse Schans, a historical town with windmills and traditional crafts. Enjoy a canal cruise in Amsterdam and a short orientation tour at Dam Square.
                                <br/><br/>
                                <strong>Overnight in Germany. (Breakfast, Lunch and Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSix">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Day 6: Visit Cologne Cathedral, Travel To The Heart Of The Black Forest And Visit Switzerland’s Largest Waterfall – The Rhine Falls
                            </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Visit the Cologne Cathedral, a World Heritage site. Then head into the Black Forest and visit a cuckoo clock workshop. Continue to Schaffhausen to see the Rhine Falls before checking in to the hotel in Switzerland.
                                <br/><br/>
                                <strong>Overnight stay in Switzerland. (Breakfast, Lunch and Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSeven">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                Day 7: Magical Alpine Excursion To The Top Of Europe – The Amazing Jungfraujoch And Scenic Interlaken (Extra Cost)
                            </button>
                        </h2>
                        <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Optional excursion to Jungfraujoch, the Top of Europe. Enjoy a trip to the highest railway station in Europe at 11,333 feet, visit the Ice Palace, and the Sphinx observatory deck for panoramic views.
                                <br/><br/>
                                <strong>Overnight in Central Switzerland. (Breakfast, Lunch and Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingEight">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                Day 8: Zurich City Tour – World’s First Rotating Cable Car Rotair Up Mt. Titlis Peak At 3,020m
                            </button>
                        </h2>
                        <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Explore Zurich, including highlights such as the National Museum, Bahnhofstrasse, and the picturesque harbor of Enge. Then visit Engelberg and take the Rotair cable car to Mt Titlis. Enjoy the “Cliff Walk” and tour Lucerne, including the Lion Monument and Chapel Bridge.
                                <br/><br/>
                                <strong>Overnight in Zurich. (Breakfast, Lunch, Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingNine">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                Day 9: Train Ride At The Capital Of Liechtenstein, Vaduz, Visit Swarovski Crystal Museum And The Tyrolean Capital Of Austria, Innsbruck
                            </button>
                        </h2>
                        <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Travel to Vaduz for a scenic train ride. Continue to Wattens to visit the Swarovski World of Crystals. Finally, head to Innsbruck for a tour including the Golden Roof and Maria Theresa Square.
                                <br/><br/>
                                <strong>Overnight in Innsbruck. (Breakfast, Lunch and Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                Day 10: Venice And Murano Glass Demonstration
                            </button>
                        </h2>
                        <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Head to Venice, known for its picturesque canals. Enjoy a guided tour that includes a visit to the Murano glass-making factory for a demonstration, followed by free time in the main square, Piazza San Marco.
                                <br/><br/>
                                <strong>Overnight in Padova. (Breakfast, Lunch and Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingEleven">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                Day 11: Guided City Tour Of Florence – View Leaning Tower Of Pisa
                            </button>
                        </h2>
                        <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Head to Florence for a guided city tour, visiting sights such as the Cathedral of Santa Maria del Fiore, the Baptistery, and Giotto’s Bell Tower. Continue to Pisa to see the famous Leaning Tower.
                                <br/><br/>
                                <strong>Overnight in Arezzo. (Breakfast, Lunch and Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwelve">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                Day 12: All Roads Lead To Rome – Vatican Museum With Sistine Chapel – Guided City Tour Of Rome – Entrance To Colosseum
                            </button>
                        </h2>
                        <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Visit Vatican City, including the Vatican Museum and the Sistine Chapel. Enjoy a guided city tour of Rome, visiting landmarks such as the Colosseum, Trevi Fountain, and St. Peter's Basilica.
                                <br/><br/>
                                <strong>Overnight in Rome. (Breakfast, Lunch and Dinner)</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThirteen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                Day 13: Fly Back Home
                            </button>
                        </h2>
                        <div id="collapseThirteen" class="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Your memorable holiday ends with a departure transfer to the airport in Rome.
                                <br/><br/>
                                <strong>Return back to India with pleasant memories of your European tour. (Breakfast)</strong>
                            </div>
                        </div>
                    </div>
                </div>                
                <div class="inclusions m-5 p-4">
                    <h5 class="inclusion-heading">Inclusions</h5>
                    <div class="inclusion-lists">
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>London</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>London City Tour with Changing of the Guards</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Ride the London Eye</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit Madame Tussauds London</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit Stonehenge and Oxford in a day</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit the Bicester Village in London for Shopping</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>UK Visa Fees Included (with Guaranteed Appointments)</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Daily Indian Lunches and Dinners</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Paris</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit to the 2nd level of the Eiffel Tower</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Enjoy romantic cruise on River Seine</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Travel from London to Paris by Le Shuttle or Ferry</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>City tour with a local guide</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Multiple Entry Schengen Visa (with Guaranteed Visa Appointments)</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Daily Indian Lunches and Dinners</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Full Day Excitement at Euro Disney</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Standard Travel Insurance (coverage of USD 50,000 per traveler) throughout Europe</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Amsterdam</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Photo stop at the Atomium</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>The magnificent Grand Place and Manneken Pis Statue</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Enjoy sights of Amsterdam on a Canal Cruise</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Walk through the world’s largest and the most beautiful floral display at Keukenhof (till 12th May)</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit Zaanse Schaans (countryside in Netherlands with Windmills) post 13th May</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Amsterdam Orientation Tour with Dam Square Visit</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Daily Indian Lunches and Dinners</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Cologne</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit The Cologne Cathedral</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Transfer through the Black Forest Region</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit Cuckoo Clock Making at Drubba</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Daily Indian Lunches and Dinners</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Zurich</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Ride in the world’s first revolving cable car</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>View the magnificent Rhine Falls at Schaffhausen</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Orientation tour of Lucerne</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Witness breath-taking mountain peaks and glaciers at Mt. Titlis</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Zurich Orientation Tour covering Bahnofstrasse, Zurich Lake, Grossmunster and Fraumunster</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Daily Indian Lunches and Dinners</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Full Day Interlaken and Top of Jungfraujoch</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Innsbruck</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Orientation of Vaduz, the Capital of the principality of Lichtenstein</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Experience the toy train ride at Vaduz</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit the Swarovski Crystal Museum and shop</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>See the Maria Theresien Strasse and the Golden Roof</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Daily Indian Lunches and Dinners</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Padova</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Enjoy a classic Venetian Gondola ride</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>St. Mark’s square by Private boat</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Daily Indian Lunches and Dinners</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Arezzo</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit Square of Miracles</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit the Vatican City (smallest country in the world)</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Photo-stop at the Leaning Tower of Pisa</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit the beautiful Trevi Fountains</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Visit the magnificent St. Peter’s Basilica</h6>
                        </div>
                        <div class="inc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Daily Indian Lunches and Dinners</h6>
                        </div>
                    </div>
                </div>              
                <div class="exclusions m-5 p-4">
                    <h5 class="inclusion-heading">Exclusions</h5>
                    <div class="inclusion-lists">
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Tipping is expected from anyone providing your service e.g. Coach Driver, Local Guides, etc.</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>It is mandatory to tip a nominal amount of EUR 3 per person/per day. For the entire duration of this tour which is 10 Days, the total Tips amount is Euro 30 per person to be paid to the Tour Manager on Day 01 of the tour.</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>The tour cost does not include POE charges</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>No porterage included</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Private airport transfers, Cost of Suggested/Optional Excursions</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Any expenses of personal nature such as porterage, laundry expenses, wines, mineral water, food and drink not in the regular menu provided by us, minibar, telephone calls, etc.</h6>
                        </div>
                        <div class="exc-list d-flex">
                            <div class="me-2 mt-1"></div>
                            <h6>Meals other than that what is mentioned in your itinerary.</h6>
                        </div>
                    </div>
                </div>                                
                <div class="payment-policy p-4">
                    <h5 class="policy-head">Payment Policy</h5>
                    <div class="policy-content">
                        <h6>
                            <ul>
                                <li>Booking amount is minimum Rs.40,000 per person if booking is done 45 days before travel.</li>
                                <li>Within 21-45 days of travel minimum 25% booking deposit is required to confirm the booking.</li>
                                <li>Within 21 days of travel 100% of the amount is required to confirm the booking.</li>
                                <li>Passport should be valid for 6 months from the date of departure.</li>
                                <li>The tour includes Flights, Visa and Insurance.</li>
                                <li>The Tour can be cancelled 30 days before travel by paying 10% of the tour value as cancellation charges.</li>
                                <li>Within 15 days - 30 days of travel the tour can be cancelled by paying 50% of the cancellation charge.</li>
                                <li>Within 15 days the tour is completely non-refundable.</li>
                                <li>Please note the tour cost is a combination of EUR component and INR price.</li>
                                <li>The EUR component for the tour is 2,700 EUR (currently taken at a ROE of 90 per INR).</li>
                                <li>If there are any changes in EUR and ROE (Rate of Exchange) of EUR to INR increases beyond 90 at the time of travel, the difference will be payable extra and collected at the time of balance payment.</li>
                                <li>In case of any visa rejection, the cancellation charges will be Rs. 20,000 + actual visa fees will be charged and the balance will be refunded.</li>
                            </ul>
                        </h6>
                    </div>
                </div>                                
            </div>
        </div>
    </div>
  )
}

export default Grandtoure
