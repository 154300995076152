import React from 'react';

const DetailHeader = () => {
  return (
    <div className="detail-header">
      <img className="img-fluid" src="./Assets/images/Best of Europe- Tour Detail.jpg" alt="" />
    </div>
  );
};

export default DetailHeader;
