import React, { useState } from 'react';
import axios from 'axios';
import { Modal } from "bootstrap";

const Enquire = (props) => {
  let URL='https://soaraway-api.zapto.org'
  const [formData, setFormData] = useState({
    name: '',
    contactNumber: '',
    email: '',
    message: '',
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Update form data as user types
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${URL}/contact/contact`, formData);
      setResponseMessage(response.data);
      setErrorMessage('');
    } catch (error) {
      console.error("Error submitting contact form:", error);
      setErrorMessage('There was an error sending your message. Please try again later.');
      setResponseMessage('');
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="enquiryModal"
        tabIndex="-1"
        aria-labelledby="enquiryModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="enquiryModalLabel">{props.name}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control rounded-pill px-3"
                    id="name"
                    name="name"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group my-3">
                  <input
                    type="tel"
                    className="form-control rounded-pill px-3"
                    id="contactNumber"
                    name="contactNumber"
                    placeholder="Contact Number"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group my-3">
                  <input
                    type="email"
                    className="form-control rounded-pill px-3"
                    id="email"
                    name="email"
                    placeholder="Email ID"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group my-3">
                  <textarea
                    className="form-control rounded-4 px-3"
                    id="message"
                    name="message"
                    placeholder="Message"
                    rows="6"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-check my-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="privacyPolicy"
                    required
                  />
                  <label className="form-check-label" htmlFor="privacyPolicy">
                    I have read and agree to the Privacy Policy
                  </label>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary rounded-pill w-100 px-3 modal-btn"
                >
                  Send Email
                </button>
              </form>
              {responseMessage && <p className="text-success mt-3">{responseMessage}</p>}
              {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquire;
