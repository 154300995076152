import React, { useEffect, useState } from 'react';
import axios from 'axios';

const About = () => {
  let URL='https://soaraway-api.zapto.org'
  const [teamMembers, setTeamMembers] = useState([]); // State to hold team members
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    // Fetch team members from the API
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${URL}/team/team`);
        setTeamMembers(response.data); // Set the fetched data to state
        setLoading(false); // Set loading to false after fetching
      } catch (error) {
        console.error('Error fetching team members:', error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchTeamMembers(); // Call the fetch function
  }, []);

  return (
    <div>
      <div className="about-header">
        <img className="img-fluid" src="./Assets/images/About-Us.jpg" alt="About Us" />
        <div className="grundge1">
          <img className="img-fluid w-100" src="./Assets/images/Grundge.png" alt="Decoration" />
        </div>
      </div>

      <div className="container about-us my-5 text-center">
        <div className="col-12">
          <h1 className="fw-bolder about-heading">ABOUT US</h1>
        </div>
        <div className="col-12 my-5 about-text">
          <p className="mt-2">
            Welcome to Soar Away - your gateway to extraordinary travel experiences. With a seasoned team boasting 7 years in the industry, we ensure a deep understanding of crafting journeys that go beyond the ordinary.
          </p>
        </div>
      </div>

      {/* Team Section */}
      <div className="team-section">
        <div className="container text-center">
          <h1 className="fw-bold team-heading">Meet Our Team</h1>
          <p className="team-intro">The talented individuals who drive our success.</p>

          <div className="team-cards d-flex flex-wrap justify-content-center">
            {loading ? (
              <p>Loading...</p> // Loading state
            ) : (
              teamMembers.map((member) => (
                <div className="team-card" key={member.email}> {/* Use unique key */}
                  <img src={member.profile_pic || './Assets/images/default.jpg'} alt={member.name} className="team-img" /> {/* Default image if not available */}
                  <div className="overlay">
                    <h3>{member.name}</h3>
                    <p>{member.position}</p> {/* Display position */}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
