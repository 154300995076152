import React from 'react';

const CancellationPolicy = () => {
  return (
    <div className="cancellation-policy p-4">
      <h5 className="cancellation-head">Cancellation Policy</h5>
      <div className="cancellation-content">
        <h6>
          <ul>
            <li>The Tour can be cancelled 30 days before travel by paying 10% of the tour value as cancellation charges.</li>
            <li>Within 15 days - 30 days of travel the tour can be cancelled by paying 50% of the cancellation charge.</li>
            <li>Within 15 days the tour is completely non-refundable.</li>
            <li>Please note the tour cost is a combination of EUR component and INR price...</li>
          </ul>
        </h6>
      </div>
    </div>
  );
};

export default CancellationPolicy;
