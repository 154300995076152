// <!-- Contact-Us-Modal -->

import React from 'react'
import './contact.css'

const Contact = () => {
  return (
    <div>
        <div class="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="contactModalLabel">Contact Us</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group mb-3">
                <input type="text" class="form-control rounded-pill px-3" id="name" name="name" placeholder="Full Name"/>
              </div>
              <div class="form-group my-3">
                  <input type="tel" class="form-control rounded-pill px-3" id="contactNumber" placeholder="Contact Number" required/>
              </div>
              <div class="form-group my-3">
                <input type="email" class="form-control rounded-pill px-3" id="email" name="email" placeholder="Email ID"/>
              </div>
              <div class="form-group my-3">
                <textarea class="form-control rounded-4 px-3" id="message" name="message" placeholder="Message" rows="6"></textarea>
              </div>
              <div class="form-check my-3">
                  <input type="checkbox" class="form-check-input" id="privacyPolicy" required/>
                  <label class="form-check-label" for="privacyPolicy">I have read and agree to the Privacy Policy</label>
              </div>
              <button type="submit" class="btn btn-primary rounded-pill w-100 px-3 modal-btn">Send Email</button>
            </form>
          </div>
        </div>
      </div>
</div>
      
    </div>
  )
}

export default Contact

