import React from 'react';

const Exclusions = () => {
  return (
    <div className="exclusions m-5 p-4">
      <h5 className="inclusion-heading">Exclusions</h5>
      <div className="inclusion-lists">
        <div className="exc-list d-flex">
          <div className="me-2 mt-1"></div>
          <h6>
            Tipping is expected from anyone providing your service e.g. Coach Driver, Local Guides,
            etc. It is mandatory to tip a nominal amount of EUR 3 per person/per day. For the entire
            duration of this tour which is 13 Days, the total Tips amount is Euro 39 per person to be
            paid to the Tour Manager on Day 01 of the tour.
          </h6>
        </div>
        {/* Continue with all exclusions as per the original HTML */}
      </div>
    </div>
  );
};

export default Exclusions;
