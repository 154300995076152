import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function SearchForm() {
    let URL='https://soaraway-api.zapto.org'
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  // Capitalize the first letter of each word as the user types
  const handleInputChange = (e) => {
    const words = e.target.value.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    setSearchTerm(capitalizedWords.join(" "));
  };

  const handleSearch = async () => {
    try {
      // Call the API to fetch the list of places
      const response = await axios.get(`${URL}/package/packages`);
      const places = response.data; // Assume this is an array of places

      // Find the place matching the search term (case insensitive)
      const matchedPlace = places.find(
        (place) => place.placeName=== searchTerm
      );

      if (matchedPlace) {
        // Navigate to the details page with the matched place ID
        navigate(`/besteurope/${encodeURIComponent(matchedPlace.name)}`);
      } else {
        alert("Place not found. Please try another search term.");
      }
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  return (
    <form className="d-flex" role="search" onSubmit={(e) => e.preventDefault()}>
      <input
        className="form-control"
        size="30"
        type="search"
        placeholder="i.e. Dubai, Singapore"
        aria-label="Search"
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <button className="btn btn-warning w-50 navbtn" type="button" onClick={handleSearch}>
        Search place
      </button>
    </form>
  );
}

export default SearchForm;
