import React from "react";
import "./Home.css";
import Home1 from "../Images/Home.jpg";
import Grundge from "../Images/Grundge.png";
import PD1 from "../Images/Popular Destination-1 Europe.jpg";
import PD2 from "../Images/Popular Destination-2 Dubai.jpg";
import PD3 from "../Images/Popular Destination-3 Singapore.jpg";
import PD4 from "../Images/Popular Destination-4 Azerbaijaan.jpg";
import Gallery_1 from "../Images/Gallery-1.jpg";
import Gallery_2 from "../Images/Gallery-2.jpg";
import Gallery_3 from "../Images/Gallery-3.jpg";
import Gallery_4 from "../Images/Gallery-4.jpg";
import Gallery_5 from "../Images/Gallery-5.jpg";
import Gallery_6 from "../Images/Gallery-6.jpg";
import Gallery_7 from "../Images/Gallery-7.jpg";
import Gallery_8 from "../Images/Gallery-8.jpg";

import BestValueTrips from "./BestValueTrips";

const Home = () => {
    let URL='https://soaraway-api.zapto.org/'
  
  return (
    <>
      {/* Home Header Section */}
      <div className="home-header">
        <img className="img-fluid" src={Home1} alt="Beautiful destination" />
        <div className="grundge1">
          <img className="img-fluid w-100" src={Grundge} alt="Grunge overlay" />
        </div>
      </div>

      {/* Popular Destinations Section */}
      <section className="container popular-destinations my-5">
        <div className="col-12 text-center">
          <h1 className="fw-bolder">Popular Destinations</h1>
          <h6 className="mb-5">World's best tourist destinations</h6>
        </div>
        <div className="row d-flex flex-wrap justify-content-center">
          {[PD1, PD2, PD3, PD4].map((image, index) => {
            const destinationNames = ["Europe", "Dubai", "Singapore", "Azerbaijan"];
            return (
              <div key={index} className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-4">
                <img className="rounded-4 ratio" src={image} alt={destinationNames[index]} />
                <h3 className="popular-destinations-text mt-2">{destinationNames[index]}</h3>
              </div>
            );
          })}
        </div>
      </section>

      {/* Best Value Trips Section */}
      <BestValueTrips />

      {/* Gallery Section */}
      <section className="container-fluid gallery my-5">
        <div className="col-12 text-center">
          <h1 className="fw-bold">Gallery</h1>
          <h6>Best offers trips from us</h6>
        </div>
        <div className="gallery-pictures my-5">
          <div className="container d-flex flex-wrap justify-content-center">
            {[Gallery_1, Gallery_2, Gallery_3, Gallery_4, Gallery_5, Gallery_6, Gallery_7, Gallery_8].map((galleryImage, index) => (
              <div key={index} className="content">
                <img src={galleryImage} alt={`Gallery image ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
