import React from 'react';

const Inclusions = () => {
  return (
    <div className="inclusions m-5 p-4">
      <h5 className="inclusion-heading">Inclusions</h5>
      <div className="inclusion-lists">
        <div className="inc-list d-flex">
          <div className="me-2 mt-1"></div>
          <h6>09 Nights Accommodation with Breakfast</h6>
        </div>
        <div className="inc-list d-flex">
          <div className="me-2 mt-1"></div>
          <h6>Vehicle on disposal basis for 09 days, 10 Hrs. on everyday</h6>
        </div>
        {/* Continue with all inclusions as per the original HTML */}
      </div>
    </div>
  );
};

export default Inclusions;
