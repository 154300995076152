import React from 'react';

const Itinerary = () => {
  return (
    <div className="col-xl-8 itenary">
      <div className="mx-5 accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header m-4 head">Itinerary</h2>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              Day 1: Arrival In Paris, The City Of Romance, Lights And Glamour
            </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              <ul>
                <li>Arrival in Paris with a guide waiting to escort you to the hotel and help with check-in.</li>
                <li>Overnight in Paris. (Dinner)</li>
              </ul>
            </div>
          </div>
        </div>
        {/* Repeat for other days */}
      </div>
    </div>
  );
};

export default Itinerary;
