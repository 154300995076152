import React from 'react';
import DetailHeader from './DetailHeader';
import Itinerary from './Itinerary';
import Inclusions from './Inclusions';
import Exclusions from './Exclusions';
import PaymentPolicy from './PaymentPolicy';
import CancellationPolicy from './CancellationPolicy';
import  "./europe.css";

const TourDetail = () => {
  return (
    <div id="detail">
      {/* Other Components */}
      <DetailHeader />
      <div className="row m-0">
        <Itinerary />
      </div>
      <Inclusions />
      <Exclusions />
      <PaymentPolicy />
      <CancellationPolicy />
    </div>
  );
};

export default TourDetail;
