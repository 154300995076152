import React, { useState, useEffect } from "react";
import "./Navbar.css";
import SA_Logo from "../Images/SA-Logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal } from "bootstrap";
import axios from "axios"; // Import axios to fetch data
import Enquire from "../Enquire";
import { Link, useNavigate } from "react-router-dom";
import SearchForm from "../SearchForm";

const Navbar = () => {
  let URL='https://soaraway-api.zapto.org'
  const [isInternationalOpen, setInternationalOpen] = useState(false);
  const [isDomesticOpen, setDomesticOpen] = useState(false);
  const [internationalPackages, setInternationalPackages] = useState([]);
  const [domesticPackages, setDomesticPackages] = useState([]);
  // const [isInternationalOpen, setInternationalOpen] = useState(false);
  // const [isDomesticOpen, setDomesticOpen] = useState(false);
  const [offcanvasInternationalOpen, setOffcanvasInternationalOpen] = useState(false);
  const [offcanvasDomesticOpen, setOffcanvasDomesticOpen] = useState(false);
  const [offcanvasEuropeOpen, setOffcanvasEuropeOpen] = useState(false);
  const [placeName, setPlaceName] = useState('');

  
  // const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const contactModalEl = document.getElementById("contactModal");
    const enquiryModalEl = document.getElementById("enquiryModal");

    if (contactModalEl && enquiryModalEl) {
      new Modal(contactModalEl);
      new Modal(enquiryModalEl);
    } else {
      console.error("Modal elements not found.");
    }

    let lastScrollTop = 0;
    const navbar = document.querySelector(".navbar");

    if (!navbar) {
      console.error("Navbar element not found.");
      return;
    }

    const handleScroll = () => {
      const currentScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScroll > lastScrollTop && currentScroll > navbar.clientHeight) {
        navbar.classList.add("navbar-hidden");
      } else {
        navbar.classList.remove("navbar-hidden");
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

     // Fetch the package data from the backend API
     useEffect(() => {
     const fetchPackages = async () => {
      try {
        const response = await axios.get(`${URL}/package/packages`, {
          params: { placeName: placeName },
        }); // Your API endpoint
        const packages = response.data;
      

        

        // Separate the packages based on the type
        const international = packages.filter(pkg => pkg.packageType === 'international');
        const domestic = packages.filter(pkg => pkg.packageType === 'domestic');
        

        // Set the state for each category
        setInternationalPackages(international);
        setDomesticPackages(domestic);
       
      } catch (error) {
        console.error("Error fetching packages", error);
      }
    };

    fetchPackages();
  }, []);

  // // Function to handle input change
  // const handleInputChange = (e) => {
  //   setSearchInput(e.target.value);
  // };

 




  return (
    <>
    <div className="navbar bg-body-tertiary fixed-top">
      <div className="container">
        <div className="col-3">
          <a href="/" className="navbar-brand">
            <img src={SA_Logo} alt="Logo" width="100px" />
          </a>
        </div>

        <button
          className="navbar-toggler d-xl-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="col-9 d-none d-xl-block">
          <ul className="navbar-nav d-flex flex-row justify-content-between">
            <li className="nav-item">
            <SearchForm/>

      {/* {error && <p style={{ color: 'red' }}>{error}</p>} Error message */}
      
            </li>

            <li className="nav-item">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/tours">
                Tours
              </a>
            </li>
            <li
              className="nav-item dropdown-custom"
              onMouseEnter={() => setInternationalOpen(true)}
              onMouseLeave={() => setInternationalOpen(false)}
            >
              <a className="nav-link" href="#">
                International &#9660;
              </a>
              <div
                className="custom-dropdown"
                style={{
                  visibility: isInternationalOpen ? "visible" : "hidden",
                  opacity: isInternationalOpen ? 1 : 0,
                }}
              >
                <ul>
                {internationalPackages.map(pkg => (
                      <li key={pkg._id}>
                        <a href={`/besteurope/${encodeURIComponent(pkg.name)}`}>
                          {pkg.placeName} 
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </li>
            <li
              className="nav-item dropdown-custom"
              onMouseEnter={() => setDomesticOpen(true)}
              onMouseLeave={() => setDomesticOpen(false)}
            >
              <a className="nav-link" href="#">
                Domestic &#9660;
              </a>
              <div
                className="custom-dropdown"
                style={{
                  visibility: isDomesticOpen ? "visible" : "hidden",
                  opacity: isDomesticOpen ? 1 : 0,
                }}
              >
                <ul>
                  {domesticPackages.map(pkg => (
                    <li key={pkg._id}>
                      <a href={pkg.link}>{pkg.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/aboutUs">
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="modal" data-bs-target="#enquiryModal">
                Contact Us
              </a>
              
            </li>
            
          </ul>
          
        </div>
        

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <SearchForm/>
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/tours">
                  Tours
                </a>
              </li>
              <li className="nav-item dropdown-custom">
                <a
                  className="nav-link"
                  href="#"
                  onClick={() =>
                    setOffcanvasInternationalOpen(!offcanvasInternationalOpen)
                  }
                >
                  International 
                </a>
                {offcanvasInternationalOpen && (
                  <ul style={{ listStyle: "none" }} className="custom-dropdown">
                   {internationalPackages.map(pkg => (
                    <li key={pkg._id}>
                      <Link to={`/package/${pkg._id}`}> {/* Link to the details page */}
                        {pkg.placeName} 
                      </Link>
                    </li>
                  ))}
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown-custom">
                <a
                  className="nav-link"
                  href="#"
                  onClick={() =>
                    setOffcanvasDomesticOpen(!offcanvasDomesticOpen)
                  }
                >
                  Domestic 
                </a>
                {offcanvasDomesticOpen && (
                  <ul style={{ listStyle: "none" }} className="custom-dropdown">
                     {domesticPackages.map(pkg => (
                    <li key={pkg._id}>
                      <Link to={`/package/${pkg._id}`}> {/* Link to the details page */}
                        {pkg.placeName} 
                      </Link>
                    </li>
                  ))}
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/aboutUs">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="modal" data-bs-target="#enquiryModal" >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <button type="button" class="btn quick-enquiry-btn" data-bs-toggle="modal" data-bs-target="#enquiryModal">
            Quick Enquiry
    </button>
    <Enquire name='Quick Enquiry'/>
    <Enquire name='Contact Us'/>
  </>
  );
};

export default Navbar;
