import React from 'react';

const PaymentPolicy = () => {
  return (
    <div className="payment-policy p-4">
      <h5 className="policy-head">Payment Policy</h5>
      <div className="policy-content">
        <h6>
          <ul>
            <li>Booking amount is minimum Rs.30,000 per person if booking is done 45 days before travel.</li>
            <li>Within 21-45 days of travel minimum 25% booking deposit is required to confirm the booking.</li>
            <li>Within 21 days of travel 100% of the amount is required to confirm the booking.</li>
            <li>Passport should be valid for 6 months from the date of departure.</li>
            <li>You can pay using credit cards, debit cards, net banking, NEFT, IMPS, RTGS, SWIFT in adherence to Income Tax ACT.</li>
            <li>We charge non-refundable financing fees for overdue amounts...</li>
          </ul>
        </h6>
      </div>
    </div>
  );
};

export default PaymentPolicy;
